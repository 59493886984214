<template>
    <div class="card-ticket">
        <div class="row">
            <div class="xs12 pa-3 pb-2">
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-card-bulleted-outline"></i>
                    </div>
                    <div :class="textRight">
                        {{ item.BienKiemSoat ? item.BienKiemSoat : "" }}
                    </div>
                </div>
                <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-seat"></i>
                    </div>
                    <div :class="textRight">
                        {{
                            `Ghế | Giường: ` +
                            `${item.SoGhe || 0} | ${item.SoGiuong || 0}`
                        }}
                    </div>
                </div>
                <div :class="rowLastText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div :class="textRight">
                        <div :style="`color: ${item.MaMauTrangThai}`">
                            {{ item.TrangThai }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div
                        class="xs6 text-xs-center"
                        style="border-right: 1px solid #dadce0"
                    >
                        <DxButton
                            :disabled="
                                !$Helper.KiemTraQuyen(
                                    $t('QuyenLenhDienTu.LENHDIENTU'),
                                    $t('QuyenLenhDienTu.XoaXe'),
                                )
                            "
                            text="Xóa"
                            type="danger"
                            styling-mode="text"
                            width="100%"
                            @click="XoaXe"
                        />
                    </div>
                    <div class="xs6 text-xs-center">
                        <DxButton
                            text="Sửa"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="
                                $router.push({
                                    path: '/Thong-Tin-Xe',
                                    query: { IdPhuongTien: item.IdXe },
                                })
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!item.MaMauTrangThaiChuyenDiVDT == '#13B000'">
            <div class="xs12">
                <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                    <div class="xs12 text-xs-center">
                        <DxButton
                            text="Tạo chuyến đi bán vé"
                            type="default"
                            styling-mode="text"
                            width="100%"
                            @click="TaoChuyenDiBanVe(item)"
                            class="color-primary"
                            :disabled="
                                item.MaMauTrangThaiChuyenDiVDT == '#13B000' ? true : false
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Xác nhận cập nhật thông tin -->
    <PopupVue
        height="auto"
        v-if="ifPopupXacNhanXoa"
        v-model:dialog="PopupXacNhanXoa"
        title=""
        buttonTextLeft="Hủy"
        buttonTextRight="Đồng ý"
        class="popup-padding-8"
        @close="XacNhanXoa"
        heightScrollView="auto"
    >
        <template #content>
            <ThongBaoVue
                :message="ParamThongBao.message"
                :state="ParamThongBao.state"
                :title="ParamThongBao.title"
            />
        </template>
    </PopupVue>
</template>

<script>
import { DxButton } from "devextreme-vue";
import ThongBaoVue from "../../../../components/_Common/ThongBao.vue";
import PopupVue from "../../../../components/_Common/Popup.vue";
export default {
    components: {
        DxButton,
        ThongBaoVue,
        PopupVue,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
            ifPopupXacNhanXoa: false,
            PopupXacNhanXoa: false,
            IdXe: null,
            ParamThongBao: {
                state: "Warning",
                title: "Bạn có chắc chắn xóa thông tin xe đã chọn?",
                message: "",
            },
        };
    },
    computed: {},
    watch: {
        PopupXacNhanXoa() {
            if (!this.PopupXacNhanXoa) {
                this.NgatDOMPopup("ifPopupXacNhanXoa");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        XoaXe() {
            this.IdXe = this.item.IdXe + "";
            this.showPopup("PopupXacNhanXoa");
        },
        XacNhanXoa(bool) {
            if (bool == false) {
                this.PopupXacNhanXoa = false;
            } else if (bool == true) {
                this.PopupXacNhanXoa = false;
                this.ThucHienXoaXe();
            }
        },
        async ThucHienXoaXe() {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTinPhuongTien/XoaPhuongTien",
                    this.IdXe,
                );
                this.PopupXacNhanXoa = false;
                if (rs.status == true) {
                    this.$Helper.ThongBaoToast("success", "Xóa thông tin xe thành công!");
                } else if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: `Xóa thông tin xe thất bại!`,
                        message: rs.message,
                    });
                }
                this.$emit("getData");
            } catch (ex) {
                this.PopupXacNhanXoa = false;
                this.$Helper.ThongBaoPopup({
                    message_title: `Xóa thông tin xe thất bại!`,
                    message: "Lỗi hệ thống client.",
                });
            }
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
:deep(.custom-action-sheet .dx-toolbar-label div) {
    font-size: 20px;
    font-weight: 500;
}
</style>
